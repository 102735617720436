import http, { axiosRequests } from "../utils/http.js"; export class GeneralService {
  static async searchDni(dni) {
    return (await axiosRequests.get(process.env.VUE_APP_API_DNI + dni)).data;
  }

  static async saveCategorie(reg) {
    if (reg.id) {
      return (await http.put(`api/general/categories/${reg.id}`, reg)).data;
    } else return (await http.post("api/general/categories", reg)).data;
  }

  static async getCategorie(id) {
    return (await http.get(`api/general/categories/${id}`)).data;
  }

  static async deleteCategorie(id) {
    return (await http.delete(`api/general/categories/${id}`)).data;
  }

  /**
   *
   * Subir imagenes
   *
   * @param FileList https://developer.mozilla.org/es/docs/Web/API/FileList
   */
  static uploadImages = async (files) => {
    var formData = new FormData();
    for (let f of files) {
      formData.append(f.name, f);
    }

    return (
      await http.post("api/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
    ).data;
  };

  static async uploadFiles(files) {
    var formData = new FormData();
    let c = 1;
    for (let f of files) {
      formData.append(`file_${c}`, f);
      c++;
    }
    return (
      await http.post("api/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
    ).data;
  }

  static async getEmployeeLiquidations(params) {
    return (await http.get("api/general/employee-liquidations", { params }))
      .data;
  }

  static async getEmployeeLiquidation(id) {
    return (await http.get(`api/general/employee-liquidations/${id}`)).data;
  }

  static async saveEmployeeLiquidation(reg) {
    if (reg.id)
      return (
        await http.put(`api/general/employee-liquidations/${reg.id}`, reg)
      ).data;
    else
      return (await http.post("api/general/employee-liquidations", reg)).data;
  }

  static async deleteEmployeeLiquidation(id) {
    return (await http.delete(`api/general/employee-liquidations/${id}`)).data;
  }

  // static saveLiquidation = async reg => {
  //   if (reg.id) {
  //     return (await http.put(`api/admin/liquidations/${reg.id}`, reg)).data;
  //   } else return (await http.post("api/admin/liquidations", reg)).data;
  // };

  static async saveSpecialty(reg) {
    if (reg.id) {
      return (await http.post(`api/general/specialties/${reg.id}`, reg)).data;
    } else {
      return (await http.post("api/general/specialties", reg)).data;
    }
  }

  static async getSpecialty(id) {
    return (await http.get(`api/general/specialties/${id}`)).data;
  }

  static getSpecialties = async (params) => {
    return (await http.get(`api/general/specialties`, { params })).data;
  };

  static getMeasurements = async (params) => {
    return (await http.get("api/general/measurements", { params })).data;
  };

  static getCategories = async (params) => {
    return (await http.get("api/general/categories", { params })).data;
  };

  static saveBrand = async (reg) => {
    if (!reg.id) {
      return (await http.post("api/general/brands", reg)).data;
    } else {
      return (await http.put("api/general/brands/" + reg.id, reg)).data;
    }
  };

  static getBrands = async (params) => {
    return (await http.get("api/general/brands", { params })).data;
  };

  static deleteBrand = async (id) => {
    return (await http.delete("api/general/brands/" + id)).data;
  };

  static async getProfile() {
    return (await http.get("api/user")).data;
  }

  static async saveProfile(profile) {
    return (await http.put("api/my-profile", profile)).data;
  }

  static async getUser() {
    return (await http.get("/api/user")).data;
  }
}
