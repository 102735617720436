<template>
  <div>
    <app-table-registers
      ref="tr"
      :getList="getList"
      :sm="false"
      @btnNew="
        $refs.elModalCreateCategorie.show();
        $refs.elCategorieForm.reset();
      "
    >
      <template slot="table">
        <thead>
          <tr>
            <th>Categoria</th>
            <th>Productos</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, i) in list.data" :key="i">
            <td>
              <router-link :to="enlaceProductos(area_id, l.id, l.name)">
                <b>
                  <span>{{ l.name }} </span>
                  <i class="fa fa-angle-right"></i>
                </b>
              </router-link>
            </td>
            <td>{{ l.quantity }}</td>
            <td class="p-2">
              <button class="btn btn-light btn-sm" @click="deleteItem(l.id)">
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>
    <app-modal-basic ref="elModalCreateCategorie">
      <CategorieForm
        ref="elCategorieForm"
        @submitted="
          $refs.elModalCreateCategorie.hide();
          $refs.tr.gl();
        "
      ></CategorieForm>
    </app-modal-basic>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { GeneralService } from "src/general-module/GeneralService.js";
import CategorieForm from "src/lt/general-module/categories/Form.vue";

export default {
  components: {
    CategorieForm,
  },

  // directives
  // filters

  props: {
    categorie_id: {},
  },

  data: () => ({
    list: {},
  }),

  computed: {
    ...mapState({
      area_id: (s) => s.config.dental.area_id,
    }),
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    enlaceProductos(areaId, categoryId, category) {
      return {
        path: "/dental-clinic/products/list",
        query: {
          categorie_id: categoryId,
          area_id: areaId,
          category: category,
        },
      };
    },
    async deleteItem(id) {
      if (window.confirm("Eliminar esta categoria")) {
        await GeneralService.deleteCategorie(id);
        this.$refs.tr.gl();
      }
    },
    getList(params) {
      return new Promise(() => {
        GeneralService.getCategories({
          ...params,
          count_products: 1,
          per_page: 999,
        }).then((list) => {
          this.list = list;
        });
      });
    },
  },
};
</script>

<style></style>
